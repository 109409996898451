import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { visuallyHidden } from '@mui/utils';
import { CSVLink } from 'react-csv';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ color: 'inherit', lineHeight: '1rem'}}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            colSpan={headCell.colspan || 1}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar(props) {
  const { tableTitle, isoDate, csvData } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        {tableTitle} contracts for {isoDate}
      </Typography>

      <CSVLink
        filename={`${tableTitle}-eod-report-${isoDate}.csv`}
        target="_blank"
        rel="noopener noreferrer"
        data={csvData}>
        <Tooltip title="Export to CSV">
          <IconButton>
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
      </CSVLink>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  tableTitle: PropTypes.string.isRequired,
  isoDate: PropTypes.string.isRequired,
  csvData: PropTypes.array.isRequired,
};

export default function ContractsTable(props) {
  const { title, isoDate, headCells, rows, formatter } = props;
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('volume');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <EnhancedTableToolbar tableTitle={title} isoDate={isoDate} csvData={formatter(rows)}/>
      <TableContainer>
        <Table
          sx={{ color: 'inherit'}}
          aria-labelledby="tableTitle"
          className="App-table"
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {formatter(rows.sort(getComparator(order, orderBy)))
              .map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={`${row.contract}-${index}`}
                  >
                    {headCells.map((headCell) => (
                        <TableCell
                          sx={{ color: 'inherit'}}
                          key={`${row.contract}-${index}-${headCell.id}`}
                          align={headCell.numeric ? 'right' : 'left'}
                          colSpan={headCell.colspan || 1}
                          data-label={headCell.label}
                        >
                          {row[headCell.id]}
                        </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
