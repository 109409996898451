import _s from 'underscore.string';
import moment from 'moment';

const locale = 'en-US';
const usdOptions = {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximimumFractionDigits: 2};

const usdPrice = function(cents, opts={}) {
  if (opts.fast) {
    return (cents/100).toFixed(2);
  } else {
    return (cents/100).toLocaleString(locale, usdOptions);
  }
};

const BITCOIN_DENOMINATOR = 1e8;

const btcFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'BTC',
  maximumFractionDigits: 8,
  currencyDisplay: 'name',
});

const btcPrice = function(satoshis) {
  // Per discussion in #eng: We store Satoshi in our DB.
  // Satoshi is the smallest unit of Bitcoin. Therefore we should never see 0.* in our database for Bitcoin values.
  // If our db returns 1 for a btc value (like a balance) it means 1 Satoshi (or 0.00000001 Bitcoin).
  if (satoshis % 1 !== 0 || satoshis < 0) {
    // Decimals are not allowed
    const message = 'Satoshi values must be a whole positive number';
    console.error(message);
    return 'Formatting Error';
  }

  return btcFormatter.format(satoshis / BITCOIN_DENOMINATOR);
};

const priceFormatters = {
  'USD': usdPrice,
  'BTC': btcPrice
};

function getPriceFormatter(name) {
  const uname = name.toUpperCase();
  const formatter = priceFormatters[uname];

  if (!formatter) {
    throw new Error(`No formatter found for asset ${name}`);
  }

  return formatter;
}

const formatContractName = function(contract) {
  const type = _s.capitalize(contract.type);
  const date = moment(contract.date_expires).format('M/D/YY');
  const contractName = contract.underlying_asset + ' ' + date + ' ' + type + ' $' + usdPrice(contract.strike_price);

  return contractName;
};

const formatConversationState = function(state) {
  return _s.humanize(state);
};

function formatOrderType(orderType) {
  const names = {
    'customer_limit_order': 'Limit',
    'customer_market_order': 'Market',
    'market_maker_quote': 'Quote',
    'scp_sweeper_order': 'SCP'
  };

  if (!orderType) {
    return null;
  }

  const name = names[orderType];

  if (!name) {
    throw new Error(`Unrecognized orderType ${orderType}`);
  }

  return name;
}

/*
 * Returns order details that are dependent on the current user.
 *
 * This may be replaced by a smarter serializer in the backend, not sure yet.
 */
function getUserFormattedOrderDetails(order, currentUser) {
  let trader = order.client;
  let side = order.side;
  let type = null;
  if (order.order_type != null) {
    type = formatOrderType(order.order_type);
  }

  if (order.scp_details) {
    if (order.scp_details.customer.mpid === currentUser.mpid) {
      // Current user is customer
      trader = order.scp_details.customer;
      side = side === 'bid' ? 'ask' : 'bid';  // reverse side
      type = 'SCP (Sweeper)';
    } else {
      type = 'SCP (Executing)';
    }
  }

  return {trader, side, type};
}

function getUserFormattedOrderDetailsImmutable(order, currentUser) {
  let trader = order.get('client');
  let side = order.get('side');
  let orderType = order.get('order_type');
  let scpDetails = order.get('scp_details');

  let type = null;
  if (orderType != null) {
    type = formatOrderType(orderType);
  }

  if (scpDetails) {
    if (scpDetails.getIn(['customer', 'mpid']) === currentUser.mpid) {
      // Current user is customer
      trader = scpDetails.get('customer');
      side = side === 'bid' ? 'ask' : 'bid';  // reverse side
      type = 'SCP (Sweeper)';
    } else {
      type = 'SCP (Executing)';
    }
  }

  return { trader, side, type };
}


export default {
  usdPrice: usdPrice,
  btcPrice: btcPrice,
  contractName: formatContractName,
  priceFormatters: priceFormatters,
  getPriceFormatter,
  conversationState: formatConversationState,
  orderType: formatOrderType,
  getUserFormattedOrderDetails,
  getUserFormattedOrderDetailsImmutable,
  dateString: 'dd M/D/YY h:mm:ss A'
};
